@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-family: 'Saira Stencil One', sans-serif;
  text-transform: uppercase;
}

p {
  font-family: 'Montserrat', sans-serif;
}

::placeholder {
  font-family: "Montserrat", sans-serif;
}

img {
  image-rendering: auto;
  image-rendering: crisp-edges;
}

.active {
  border-bottom: 3px solid #f9a825
}

.homeLogo {
  color: #fff;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 250px);
  max-width: 850px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 100px;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  padding: 20px 25px;
}

.tooltipStyle {
  font-family: 'Montserrat', sans-serif;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 330px) {
  p {
    font-size: 16px;
  }
}


